import request from '../utils/request'

/**
 * 获取文章分类列表
 */
export const getAllCategories = () => {
    let data = ``
    return request.get('/article/categories/tree', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => {
        let code = res.data.code
        return (code === 200) ? res.data.data : []
    })
}

/**
 * 获取对应的子级分类
 * @param {父级分类} parentId 
 * @returns 
 */
export const getSubCategories = (parentId) => {
    let data = ``
    return request.get(`/article/categories/tree/${parentId}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => {
        let code = res.data.code
        return (code === 200) ? res.data.data : []
    })
}

