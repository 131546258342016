<template>
    <van-nav-bar title="分类" placeholder fixed safe-area-inset-top></van-nav-bar>

    <div class="category_page">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <section v-for="category in article_categories" :key="category.categoryId">

                <h4 class="category_title"> {{ category.title }} </h4>

                <template v-if="category.isAlbum == 1">
                    <section :key="category.categoryId">
                        <van-cell :title="category.title" is-link :to="`/album/${category.categoryId}/${category.title}`" />
                    </section>
                </template>

                <template v-if="category.child">
                    <section v-for="child_item in category.child" :key="child_item.categoryId">
                        <template v-if="child_item.isAlbum == 1">
                            <van-cell :title="child_item.title" is-link :to="`/album/${child_item.categoryId}/${child_item.title}`" />
                        </template>
                        <template v-else>
                            <van-cell :title="child_item.title" is-link :to="`/articles/${child_item.categoryId}/${child_item.title}`" />
                        </template>
                    </section>
                </template>

                <template v-else>
                    <section :key="category.categoryId">
                        <van-cell :title="category.title" is-link :to="`/articles/${category.categoryId}/${category.title}`" />
                    </section>
                </template>

            </section>
        </van-pull-refresh>
    </div>
</template>

<script>
import { getAllCategories } from '../../api/article_category'
import { keepAliveMixin } from '../../mixins'

export default {
    name: 'CategoryPage',
    mixins: [keepAliveMixin],
    components: {
    },
    setup() {
    },
    mounted() {
    },
    data() {
        return {
            refreshing: false,
            article_categories: []
        }
    },
    created() {
        this.onRefresh()
    },
    methods: {
        async onRefresh() {
            this.refreshing = true;
            this.article_categories = await getAllCategories()
            this.refreshing = false;
        }
    }
}
</script>

<style lang="stylus" scoped>
    .category_page
        background-color: #e8e8e8

        .category_title
            padding-left: 10px
</style>